<template>
  <div style="position:relative; height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
      <v-row>
        <v-col lg="8" offset-lg="2">
          <v-card class="pa-6 mt-7">
            <div class="d-flex justify-space-between">
              <h3 class=mb-7>{{ $t('notice.create.title') }}</h3>
              <v-btn
                  text
                  elevation="0"
                  small
                  @click="dialogOpen"
                  :loading="btn_delete_loading"
                  :disabled="btn_delete_loading"
              >
                <v-icon size="24" color="red darken-4">mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
            <v-select
                v-if="active_customers"
                :items="customers"
                :label="$t('notice.create.customers')"
                item-text="full_name"
                item-value="id"
                v-model="form.customer_id"
                :disabled="customers_disabled"
                outlined
            ></v-select>
            <v-textarea
                outlined
                :label="$t('notice.create.textarea')"
                v-model="form.text"
                :error="errors.text!==undefined"
            ></v-textarea>
            <v-row>
              <v-col sm="12" md="4" lg="6">
                <div class="d-flex">
<!--                  <div class="d-flex align-center mr-12">-->
<!--                    <v-switch-->
<!--                        class="mr-2"-->
<!--                        v-model="form.status"-->
<!--                    ></v-switch>-->
<!--                    <span style="color: rgba(0, 0, 0, 0.6)">{{ $t('notice.status.title') }}</span>-->
<!--                  </div>-->
<!--                  <div class="d-flex align-center">-->
<!--                    <v-switch-->
<!--                        class="mr-2"-->
<!--                        v-model="active_customers"-->
<!--                    ></v-switch>-->
<!--                    <span style="color: rgba(0, 0, 0, 0.6)">{{ $t('notice.notice_about_a_customer') }}</span>-->
<!--                  </div>-->
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" class="pr-0 ml-auto">
                <v-btn
                    style="width: 100%; padding: 28px;"
                    elevation="0"
                    color="green"
                    @click="save"
                    :loading="btn_create_loading"
                    :disabled="btn_create_loading"
                >
                  {{ $t('notice.btn.save') }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-btn
                    style="width: 100%; padding: 28px;"
                    elevation="0"
                    @click="backTo"
                >
                  {{ $t('notice.btn.abort') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <small style="width: 100%; text-align: center; font-size: 14px; color: rgba(0, 0, 0, 0.6)"></small>
            <h4 style="text-align: center; width: 100%;">
              {{ $t('notice.dialog.delete') }}
            </h4>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="noticeDelete">
              {{ $t('document.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="dialogClose">
              {{ $t('document.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active_customers: false,
      dialog: false,
      customers_disabled: false,
      preloader: true,
      btn_create_loading: false,
      btn_delete_loading: false,
      customers: [],
      errors: [],
      form: {
        id: null,
        customer_id: null,
        text: '',
        status: true
      }
    }
  },
  watch: {
    'form.text'() {
      this.errors = []
    }
  },
  created() {
    this.getCustomers()
  },
  methods: {
    getNotices() {
      const notice_id = this.$route.params.id
      let notice = this.$store.getters.notices.find(n => n.id === parseInt(notice_id))

      if (notice.customer_id !== null) {
        this.active_customers = true
      }

      this.form.id = notice.id
      this.form.customer_id = notice.customer_id
      this.form.text = notice.text;
      this.form.status = notice.status;

      this.preloader = false
    },
    async getCustomers() {
      await this.$store.dispatch('customersCrm').then(() => {
        this.customers = this.$store.getters.customersCrm.data.map(customer => {
          customer['full_name'] = customer.company_name
          return customer
        })
      })

      await this.getNotices()
    },
    save() {
      this.btn_create_loading = true

      if (!this.active_customers) {
        this.form.customer_id = null
      }

      this.$store.dispatch('noticeUpdate', this.form).then(() => {
        this.backTo()
      }).catch((error) => {
        this.btn_create_loading = false
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    },
    backTo() {
      this.$router.go(-1)
    },
    noticeDelete() {
      this.btn_delete_loading = true
      this.$store.dispatch('noticeDelete', this.form.id).then(() => {
        this.backTo()
      })
    },
    dialogOpen() {
      this.dialog = true
    },
    dialogClose() {
      this.dialog = false
    },
  }
}
</script>
